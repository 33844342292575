import React, { useEffect, useMemo, useRef } from 'react';
import { client } from '../../services/client';

export default function IdleTimeout(props: React.PropsWithChildren<{}>) {
  let timeoutId = useRef<NodeJS.Timeout>();
  const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes
  const channel = useMemo(() => new BroadcastChannel('idleTimeout'), []);

  const logout = async () => {
    // Notify other tabs that the user has been logged out
    channel.postMessage('logout');
    // Clear tokens or session storage
    client.logout();
    // close the channel
    channel.close();
    // Reload the current page and let the listeners do the rest
    window.location.reload();

    console.log('User has been logged out due to inactivity');
  };

  const debouncePostMessage = (() => {
    let timer: NodeJS.Timeout;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        channel.postMessage('active');
      }, 300);
    };
  })();

  const postMessage = () => {
    debouncePostMessage();
    resetTimer();
  };

  const resetTimer = () => {
    clearTimeout(timeoutId.current);
    // Set timeout for 5 minutes of inactivity
    timeoutId.current = setTimeout(logout, INACTIVITY_TIMEOUT);
  };

  useEffect(() => {
    // Listen for user activity
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach((event) => window.addEventListener(event, postMessage));

    // Listen for messages from other tabs
    channel.onmessage = (event) => {
      if (event.data === 'logout') {
        logout();
      }
      if (event.data === 'active') {
        resetTimer();
      }
    };

    // Cleanup on component unmount
    return () => {
      clearTimeout(timeoutId.current);
      events.forEach((event) => window.removeEventListener(event, postMessage));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{props.children}</div>;
}
