import { createSlice } from '@reduxjs/toolkit';
import { DataConfig } from 'rentfree-api';
import { RootState } from 'src/app/core/store/store';
import { getConfigThunk } from './configThunk';

interface ConfigState {
  config: DataConfig | null;
  loading: boolean | null;
  error: any;
}

const initialState: ConfigState = {
  config: null,
  loading: null,
  error: null,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getConfigThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConfigThunk.fulfilled, (state, action) => {
      state.config = action.payload;
      state.loading = false;
    });
  },
});

export const selectConfig = (state: RootState) => state.config.config;
export const selectLoading = (state: RootState) => state.config.loading;
export const selectError = (state: RootState) => state.config.error;

export default configSlice.reducer;
