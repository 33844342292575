import PinInput from 'react-pin-input';
import { useState } from 'react';
import showMessage from 'src/app/core/utils/show-message';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectAuthLoading, selectUser } from '../auth/store/authSlice';
import { thunkRequestVerifyEmailToken, thunkVerifyEmail } from '../auth/store/thunks';
import './verify-email.css';
import useCountdownTimer from 'src/app/hooks/useCountdownTimer';
import { AppLogo } from 'src/app/core/components';
import { Button } from 'antd';

export default function VerifyEmail() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [confirmCode, setConfirmCode] = useState<string>();
  const [verificationHash, setVerificationHash] = useState<string>();
  const authLoading = useAppSelector(selectAuthLoading);

  const handleRequestCode = () => {
    dispatch(
      thunkRequestVerifyEmailToken({
        email: user?.email!,
      })
    )
      .unwrap()
      .then((data) => {
        setVerificationHash(data.verificationHash);
        showMessage('success', 'Email verification successfully');
        restartTimer();
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'Email verification failed');
      });
  };

  const handleConfirmCode = () => {
    dispatch(
      thunkVerifyEmail({
        otp: confirmCode!,
        email: user?.email!,
        verificationHash: verificationHash!,
      })
    )
      .unwrap()
      .then((data) => {
        window.location.reload();
        showMessage('success', 'Email verification successfully');
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'Email verification failed');
      });
  };

  const {
    restart: restartTimer,
    time: { seconds, minutes },
  } = useCountdownTimer(300000);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevent default behavior
    const pastedValue = e.clipboardData.getData('text').trim();

    if (/^\d{6}$/.test(pastedValue)) {
      setConfirmCode(pastedValue);
    }
  };

  return (
    <>
      <div className='verify-email-header'>
        <AppLogo />
      </div>
      <div className='verify-email-container'>
        <div className='verify-email'>
          <p className='verify-email-title'>Verify your email</p>
          <p>
            Please enter the 6 digit code sent to <span>{user?.email}</span>
          </p>

          <div onPaste={handlePaste}>
            <PinInput
              length={6}
              initialValue=''
              onChange={(value) => setConfirmCode(value)}
              type='custom'
              inputStyle={{ marginRight: '8px' }}
              autoSelect={true}
            />
          </div>

          <button
            className='entry-button'
            type='button'
            disabled={authLoading === true || !verificationHash}
            onClick={handleConfirmCode}
          >
            Confirm code
          </button>

          <div className='resend-verify-email'>
            <p>Didn’t get the code?</p>
            {minutes === 0 && seconds === 0 ? (
              <Button
                htmlType='button'
                type='link'
                loading={authLoading === true}
                disabled={seconds !== 0}
                onClick={handleRequestCode}
              >
                Resend code
              </Button>
            ) : (
              <p>
                Resend code in{' '}
                {` in ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
