import { createAsyncThunk } from '@reduxjs/toolkit';
import { Property, PropertyQuery } from 'rentfree-api';
import { client } from 'src/app/services/client';
import { Paginated } from '@feathersjs/feathers';

export enum FileableType {
  user = 'user',
  property = 'property',
}

export const thunkCreateProperty = createAsyncThunk(
  'adminProperties/createProperty',
  async (payload: Property) => {
    const response = await client.service('properties').create({ ...payload });

    return response;
  }
);

export const thunkUploadPropertyImg = createAsyncThunk(
  'adminProperties/UploadProperty',
  async (payload: FormData) => {
    const response = await client.service('/upload-file').create(payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }
);

export const thunkListProperties = createAsyncThunk<Paginated<Property>, PropertyQuery>(
  'adminProperties/getProperties',
  async (queries: PropertyQuery) => {
    return client.service('properties').find({
      query: {
        $limit: 20,
        deletedAt: null,
        userId: queries.userId,
        ...queries,
      },
    });
  }
);

export const thunkGetProperty = createAsyncThunk(
  'adminProperties/getProperty',
  async (id: string) => {
    return client.service('properties').get(id);
  }
);

export const thunkPatchProperty = createAsyncThunk<Property, any>(
  'adminProperties/patchProperty',
  async (payload: { id: string; data: Partial<Property> }) => {
    const { id, data } = payload;

    const response = await client.service('properties').patch(id, data);
    return response;
  }
);

export const thunkDeleteProperty = createAsyncThunk(
  'adminProperties/DeleteProperty',
  async (propertyId: string) => {
    return client.service('properties').remove(propertyId);
  }
);

export const thunkDeletePropertyFiles = createAsyncThunk(
  'adminProperties/DeletePropertyFiles',
  async (id: string) => {
    return client.service('files').remove(id);
  }
);
