import { Col, Drawer, Row } from 'antd';
import { Image } from 'antd';
import './imageGallery.css';
import { File } from 'rentfree-api';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  files: File[];
}

export default function ImageGallery(props: Props) {
  const { open, onClose, files } = props;

  return (
    <>
      <Drawer onClose={onClose} width='100vw' open={open}>
        <div className='image-grid-container'>
          {files.length > 0 ? (
            <div className='image-wrapper'>
              <Image.PreviewGroup>
                <Row gutter={[16, 16]}>
                  {files.map((src, index) => {
                    return (
                      <Col
                        key={index}
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                        className='grid-item'
                      >
                        <Image
                          width='100%'
                          height='100%'
                          src={src.url}
                          alt={`property image ${index}`}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Image.PreviewGroup>
            </div>
          ) : (
            <div>
              <p>Oops! This gallery feels a bit empty.</p>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
