import { Property } from 'rentfree-api';
import './propertyFeatures.css';
import { Server } from 'lucide-react';
import { propertyFeaturesIcons } from './propertyFeaturesIcon';

interface Props {
  property: Property;
}

export default function PropertyFeatures({ property }: Props) {
  const featuresArray = Object.entries(property.features)
    .filter(([_, value]) => value === true)
    .map(([key]) => ({
      name: key,
      Icon: propertyFeaturesIcons[key] || Server,
    }));

  return (
    <>
      <h3>Property features</h3>
      <div className='offers__details-container'>
        <div className='offers-details'>
          {featuresArray.map(({ name, Icon }) => (
            <div key={name} className='offers-details_item'>
              <Icon strokeWidth={1} />
              {name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
