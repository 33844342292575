import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import navigationReducer from 'src/app/features/admin/store/navigationSlice';
import propertyReducer from 'src/app/features/property/store/propertySlice';
import authReducer from 'src/app/features/auth/store/authSlice';
import adminPropertyReducer from 'src/app/features/admin/property/store/adminPropertySlice';
import usersReducer from 'src/app/features/admin/store/usersSlice';
import configReducer from 'src/app/core/components/store/configSlice';

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    property: propertyReducer,
    auth: authReducer,
    adminProperty: adminPropertyReducer,
    users: usersReducer,
    config: configReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
