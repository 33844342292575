import React, { useEffect, useMemo, useCallback } from 'react';
import { CONFIG_KEY, TOKEN_STORAGE_KEY } from '../../lib/variables';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  clearAuthState,
  selectIsAuthenticated,
  selectUser,
} from '../../features/auth/store/authSlice';
import { thunkGetAuthUser } from '../../features/auth/store/thunks';
import IdleTimeout from './IdleTimeout';
import { getConfigThunk } from './store/configThunk';
import { selectError, selectLoading } from './store/configSlice';
import LoadingScreen from './LoadingScreen';
import storage from 'src/app/lib/storage';
import showMessage from '../utils/show-message';

/**
 *
 * The purpose of this component is to fetch the user in oage reload,
 * it can also be used to fetch useful config and add it to the state
 */
export default function LoadAppData(props: React.PropsWithChildren<{}>) {
  const [token, setToken] = React.useState(localStorage.getItem(TOKEN_STORAGE_KEY));
  const config = useMemo(() => storage.get(CONFIG_KEY), []);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const loading = useAppSelector(selectLoading);
  const configError = useAppSelector(selectError);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const fetchConfig = useCallback(() => {
    dispatch(getConfigThunk())
      .unwrap()
      .then((data) => {
        storage.set(CONFIG_KEY, data);
      })
      .catch(() => {
        showMessage('error', 'An error occurred while fetching the config');
      });
  }, [dispatch]);

  useEffect(() => {
    if (!config) {
      fetchConfig();
    }

    if (isAuthenticated === true) {
      return;
    }

    if (!token) {
      // Try to get the token from the storage
      const localToken = localStorage.getItem(TOKEN_STORAGE_KEY);
      if (localToken) {
        setToken(localToken);
        return;
      }
      dispatch(clearAuthState());
      return;
    }

    if (!user) {
      dispatch(thunkGetAuthUser())
        .unwrap()
        .catch((error) => {
          if (error.name === 'NotAuthenticated') {
            return;
          }
        });
    }
  }, [token, user, isAuthenticated, dispatch, fetchConfig, config]);

  if (!isAuthenticated) {
    return (
      <>
        <LoadingScreen
          loading={loading === true}
          error={configError?.message}
          onRetry={fetchConfig}
        >
          {props.children}
        </LoadingScreen>
      </>
    );
  }

  return <IdleTimeout>{props.children}</IdleTimeout>;
}
