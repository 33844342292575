import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  EyeInvisibleFilled,
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Property, User } from 'rentfree-api';
import { Role } from 'rentfree-api/lib/services/users/users.shared';
import AccessControl from 'src/app/core/components/access-control/AccessControl';
import { useAppDispatch } from 'src/app/core/store/hooks';
import showMessage from 'src/app/core/utils/show-message';
import { thunkPatchProperty } from 'src/app/features/admin/property/store/thunks';
import { setProperty } from 'src/app/features/property/store/propertySlice';

/**
 * This component is used to display the admin tools for a property.
 * It displays the following tools:
 * - Edit
 * - Publish/Unpublish
 * - Delete
 * Only displayed to users with the role of admin, superadmin or the owner of the property.
 */
export default function PropertyAdminTools({
  property,
  user,
}: {
  property: Property;
  user: User | null | undefined;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <AccessControl
      permittedRoles={[Role.admin, Role.superadmin]}
      condition={() => user?.id === property.userId}
    >
      <Button
        className='sub-button'
        type='text'
        icon={<EditOutlined />}
        onClick={() => navigate(`/dashboard/properties/edit`, { state: { ...property } })}
      >
        Edit
      </Button>
      <Popconfirm
        title={`Are you sure you want to ${property.isPublished ? 'unpublish' : 'publish'} this property?`}
        onConfirm={() => {
          return dispatch(
            thunkPatchProperty({
              id: String(property.id),
              data: { isPublished: !property.isPublished },
            })
          )
            .unwrap()
            .then((property) => dispatch(setProperty(property)));
        }}
      >
        <Button
          className='sub-button'
          type='text'
          icon={property.isPublished ? <EyeOutlined /> : <EyeInvisibleFilled />}
        >
          {property.isPublished ? 'Unpublish' : 'Publish'}
        </Button>
      </Popconfirm>
      <Popconfirm
        title='Are you sure you want to delete this property?'
        onConfirm={() => {
          return dispatch(
            thunkPatchProperty({
              id: String(property.id),
              // send the deletedAt date to the server as a mysql datetime string
              data: { deletedAt: dayjs().format('YYYY-MM-DD HH:mm:ss') },
            })
          )
            .unwrap()
            .then((property) => {
              return dispatch(setProperty(property));
            })
            .then(() => {
              showMessage('success', 'Property deleted successfully');
              navigate('/dashboard/properties');
            })
            .catch((error) => {
              showMessage(
                'error',
                error.message,
                'An error occurred while deleting the property'
              );
              console.error(error);
            });
        }}
      >
        <Button
          type='text'
          className='sub-button'
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        >
          Delete
        </Button>
      </Popconfirm>
    </AccessControl>
  );
}
