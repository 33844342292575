import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import VerificationBanner from 'src/app/core/components/banners/VerificationBanner';
const { Content } = Layout;

export default function AdminContent() {
  const isVerified = false;
  return (
    <Content
      style={{
        margin: '0px 16px 0',
        overflow: 'auto',
        minHeight: '90vh',
      }}
      className='admin-dashboard-content layout-content'
    >
      {!isVerified && <VerificationBanner />}
      <div
        style={{
          marginTop: '30px',
        }}
      >
        <Outlet />
      </div>
    </Content>
  );
}
