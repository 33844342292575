// @ts-nocheck

import {
  Cctv,
  CircleParking,
  Droplet,
  Dumbbell,
  EthernetPort,
  Fence,
  Flower,
  Forklift,
  Fuel,
  GraduationCap,
  LampCeiling,
  LucideIcon,
  PawPrint,
  School,
  Server,
  Space,
  SquareParking,
  UtilityPole,
  WavesLadder,
} from 'lucide-react';

export const propertyFeaturesIcons: Record<string, LucideIcon> = {
  isFurnished: LampCeiling,
  isServiced: Server,
  hasGarden: Flower,
  hasGarage: CircleParking,
  hasPool: WavesLadder,
  hasSecurity: Cctv,
  hasInternet: EthernetPort,
  hasElectricity: UtilityPole,
  hasWater: Droplet,
  hasGas: Fuel,
  hasParking: SquareParking,
  hasGym: Dumbbell,
  studentsAllowed: School,
  petsAllowed: PawPrint,
  hasElevator: Forklift,
  hasBalcony: Space,
  builtForStudents: GraduationCap,
  hasFence: Fence,
};
