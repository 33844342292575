import { Button, Card, Col, Form, Row, Steps } from 'antd';
import { useState } from 'react';
import BasicDetails from './components/BasicDetails';
import LocationDetails from './components/LocationDetails';
import Media from './components/Media';
import FinancialDetails from './components/FinancialDetails';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { thunkCreateProperty, thunkPatchProperty } from './store/thunks';
import { useLocation, useNavigate } from 'react-router-dom';
import showMessage from 'src/app/core/utils/show-message';
import { selectProperties } from './store/adminPropertySlice';
import { Property } from 'rentfree-api';
import DOMPurify from 'dompurify';
import { FileAddOutlined } from '@ant-design/icons';
import PropertyFeatures from './components/PropertyFeatures';

export default function ListProperty() {
  const navigate = useNavigate();
  const location = useLocation();
  const property = location.state as Property;

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [propertyId, setPropertyId] = useState<string>(
    property ? String(property.id) : ''
  );
  const [descriptionError, setDescriptionError] = useState('');
  const [formData, setFormData] = useState({
    isServiced: false,
    status: 'available',
  });
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectProperties);
  const [description, setDescription] = useState(property ? property.description : '');
  const sanitizedDescription = DOMPurify.sanitize(description);

  const onFinish = (values: any) => {
    dispatch(
      thunkCreateProperty({
        description: sanitizedDescription,
        ...values,
      })
    )
      .unwrap()
      .then((data) => {
        setPropertyId(data.id);
        setCurrent((prev) => prev + 1);
        showMessage('success', 'Property created successfully');
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'unable to create property');
      });
  };

  const UpdateProperty = (data: Property) => {
    dispatch(
      thunkPatchProperty({
        id: String(property.id),
        data: { ...data, description: sanitizedDescription },
      })
    )
      .unwrap()
      .then(() => {
        setPropertyId(String(property.id));
        showMessage('success', 'Property updated successfully');
      })
      .catch(() => showMessage('error', 'Error updating Property'));
  };

  const next = async () => {
    try {
      const values = await form.validateFields();
      setFormData((prevData) => ({ ...prevData, ...values }));

      if (!description.replace(/<[^>]+>/g, '').trim()) {
        setDescriptionError('This field is required.');
        return;
      }

      setCurrent((prev) => prev + 1);
      form.setFieldsValue({ ...formData, ...values });
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
    form.setFieldsValue(formData);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalData = { ...formData, ...values };
      property ? UpdateProperty(values) : onFinish(finalData);
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const steps = [
    {
      title: 'Basic Information',
      content: (
        <BasicDetails
          description={description}
          setDescription={setDescription}
          descriptionError={descriptionError}
          property={property}
        />
      ),
    },
    {
      title: 'Property Features',
      content: <PropertyFeatures />,
    },
    {
      title: 'Financial Details',
      content: <FinancialDetails property={property} />,
    },
    {
      title: 'Location',
      content: <LocationDetails property={property} />,
    },
    {
      title: 'Media',
      content: <Media propertyId={propertyId} />,
    },
  ];

  return (
    <Row>
      <Col offset={3} span={18}>
        <Steps
          current={current}
          items={steps}
          style={{ marginBottom: 40 }}
          progressDot
          responsive
        />
        <Card>
          <Form
            form={form}
            layout='vertical'
            initialValues={{
              isFurnished: property ? property.features.isFurnished : false,
              hasGarden: property ? property.features.hasGarden : false,
              hasWater: property ? property.features.hasWater : false,
              hasElectricity: property ? property.features.hasElectricity : false,
              isServiced: property ? property.features.isServiced : false,
              hasGarage: property ? property.features.hasGarage : false,
              hasPool: property ? property.features.hasPool : false,
              hasSecurity: property ? property.features.hasSecurity : false,
              hasInternet: property ? property.features.hasInternet : false,
              hasGas: property ? property.features.hasGas : false,
              hasParking: property ? property.features.hasParking : false,
              hasGym: property ? property.features.hasGym : false,
              lga: property ? `${property.city} - ${property.lga}` : '',
            }}
          >
            <Row gutter={[16, 16]}>{steps[current].content}</Row>
            <Row justify='space-between'>
              <Col span={12} className='flex gap'>
                {current > 0 && current <= steps.length - 2 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
                {current < steps.length - 2 && (
                  <Button type='primary' onClick={() => next()}>
                    Next
                  </Button>
                )}
                {property && current === steps.length - 1 && (
                  <Button onClick={() => prev()}>Previous</Button>
                )}
                {property && current === steps.length - 2 && (
                  <Button type='primary' onClick={() => next()}>
                    Next
                  </Button>
                )}
              </Col>
              <Col span={12} className='flex gap flex-end'>
                {property && current <= steps.length - 2 ? (
                  <Button
                    type='primary'
                    onClick={handleSubmit}
                    loading={isLoading === true}
                    icon={<FileAddOutlined />}
                  >
                    Update {steps[current].title}
                  </Button>
                ) : current === steps.length - 2 ? (
                  <Button
                    type='primary'
                    onClick={handleSubmit}
                    loading={isLoading === true}
                    icon={<FileAddOutlined />}
                  >
                    Create property
                  </Button>
                ) : null}

                {current === steps.length - 1 && (
                  <Button
                    type='primary'
                    onClick={() => navigate('/dashboard/properties')}
                  >
                    Done
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
