import { notification, NotificationArgsProps } from 'antd';

export default function showMessage(
  type: 'success' | 'info' | 'warning' | 'error',
  message: string,
  title: string | null = null
) {
  const config: NotificationArgsProps = {
    message: title || type.charAt(0).toUpperCase() + type.slice(1),
    description: message,
    placement: 'top',
  };

  notification[type](config);
}
