import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'rentfree-api';
import { AuthenticationResult } from '@feathersjs/authentication';
import { client } from 'src/app/services/client';

export const thunkLogin = createAsyncThunk(
  'auth/login',
  async (payload: { email: string; password: string }) => {
    const response = await client.authenticate({
      strategy: 'local',
      ...payload,
    });

    return response;
  }
);

export const thunkSignup = createAsyncThunk('auth/signup', async (payload: User) => {
  const response = await client.service('users').create({ ...payload });

  return response;
});

export const thunkLogout = createAsyncThunk('auth/logout', async () => {
  await client.logout().then(() => window.location.replace('/'));
  return null;
});

// Get auth user
export const thunkGetAuthUser = createAsyncThunk('auth/fetchUser', async () => {
  const response: AuthenticationResult = await client.reAuthenticate(true);
  return response;
});

// verify email
export const thunkVerifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (
    payload: { otp: string; email: string; verificationHash: string },
    { dispatch }
  ) => {
    const response = await client.service('verify-email').create({ ...payload });
    // dispatch()
    return response;
  }
);

// Get request verify email token
export const thunkRequestVerifyEmailToken = createAsyncThunk(
  'auth/RequestVerifyEmailToken',
  async (payload: { email: string }) => {
    return await client.service('request-token').create({ ...payload });
  }
);

// reset password
export const thunkResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload: {
    email?: string;
    token?: string;
    password?: string;
    withToken: boolean;
  }) => {
    if (payload.withToken) {
      const response = await client.service('password-reset').patch(null, {
        token: payload.token,
        password: payload.password,
      });

      return response;
    } else {
      const response = await client
        .service('password-reset')
        .create({ email: payload.email! });

      return response;
    }
  }
);
