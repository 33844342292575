import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/app/core/store/hooks';
import { selectIsAuthenticated, selectUser } from 'src/app/features/auth/store/authSlice';

export enum Roles {
  agent = 'agent',
  client = 'client',
  landlord = 'landlord',
  admin = 'admin',
  superadmin = 'superadmin',
}
interface RoleProtectedRouteProps {
  allowedRoles: Roles[];
}

export default function RoleProtectedRoute({ allowedRoles }: RoleProtectedRouteProps) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const location = useLocation();

  const role = React.useMemo(() => {
    return Roles[user?.role!!];
  }, [user]);

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to='/unauthorized' replace />;
  }

  return <Outlet />;
}
