import * as React from 'react';
import { Navigate, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingScreen from 'src/app/core/components/LoadingScreen';
import { useAppSelector } from 'src/app/core/store/hooks';
import showMessage from 'src/app/core/utils/show-message';
import {
  selectAuthError,
  selectIsAuthenticated,
} from 'src/app/features/auth/store/authSlice';
import { TOKEN_STORAGE_KEY } from 'src/app/lib/variables';

export default function AuthRoutesGuard() {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const accessToken = searchParams.get('access_token');
  const authError = useAppSelector(selectAuthError);

  React.useEffect(() => {
    if (error) {
      const message =
        error === 'authentication_failed' ? 'Authentication failed' : 'An error occurred';
      showMessage('error', message);
    }

    if (accessToken) {
      localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
      navigate('/', { replace: true });
    }
  }, [error, accessToken, navigate]);

  React.useEffect(() => {
    if (authError?.message) {
      // This is a good place to show a toast or a modal
      // Log the hash of the error object
      showMessage('error', authError.message, 'Authentication error');
    }
  }, [authError]);

  if (isAuthenticated === true) return <Navigate to='/' />;

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      {isAuthenticated === null || (!isAuthenticated && <Outlet />)}
    </React.Suspense>
  );
}
