import { createAsyncThunk } from '@reduxjs/toolkit';
import { Property, StateLga, StateLgaQuery } from 'rentfree-api';
import { client } from 'src/app/services/client';
import { Paginated } from '@feathersjs/feathers';
import { GetPropertiesQuery } from 'src/app/core/types/property';

export const thunkLgaSearch = createAsyncThunk<Paginated<StateLga>, StateLgaQuery>(
  'property/lgaSearch',
  async (payload) => {
    return client.service('lgas').find({
      query: {
        ...payload,
      },
    });
  }
);

export const thunkGetProperties = createAsyncThunk<
  Paginated<Property>,
  GetPropertiesQuery
>('properties/getProperties', async (queries: GetPropertiesQuery) => {
  const { minBed, maxBed, maxPrice, minPrice, location, propertyType, ...query } =
    queries;
  const [state, lga] = location?.split('-') ?? [];
  const response = await client.service('properties').find({
    query: {
      $limit: 20,
      price: { $gt: minPrice, $lt: maxPrice },
      bedrooms: { $gt: minBed, $lt: maxBed },
      propertyType: { $in: propertyType?.split('&') },
      ...(state && { state: state.trim() }),
      ...(lga && { lga: lga.trim() }),
      ...query,
    },
  });

  return response;
});

export const thunkGetProperty = createAsyncThunk<Property, string>(
  'properties/getProperty',
  async (id: string) => {
    return client.service('properties').get(id);
  }
);

export const thunkSaveProperty = createAsyncThunk(
  'properties/saveProperty',
  async (payload: { propertyId: string }, { dispatch }) => {
    const response = await client.service('/saved-properties').create({ ...payload });
    dispatch(thunkGetProperty(payload.propertyId));
    return response;
  }
);
