import React, { useState } from 'react';
import { Spin, Image, Upload, UploadFile, message } from 'antd';
import { TOKEN_STORAGE_KEY } from 'src/app/lib/variables';
import {
  FileableType,
  thunkDeletePropertyFiles,
  thunkGetProperty,
} from '../store/thunks';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectProperties } from '../store/adminPropertySlice';
import { LoadingOutlined } from '@ant-design/icons';
import { allowedExtensions } from 'rentfree-api/lib/services/files/files.shared';
import './media.css';
import { propertyFile } from 'src/app/core/types/property';
import { UploadFileStatus } from 'antd/es/upload/interface';

interface Props {
  propertyId?: string;
}

const { Dragger } = Upload;

export default function Media({ propertyId }: Props) {
  const api = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useAppDispatch();
  const { property, isLoading } = useAppSelector(selectProperties);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  React.useEffect(() => {
    dispatch(thunkGetProperty(propertyId!));
  }, [dispatch, propertyId]);

  const token = React.useMemo(() => localStorage.getItem(TOKEN_STORAGE_KEY), []);

  const handleCustomRequest = async (options: any) => {
    const { file, onError } = options;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('fileableId', propertyId!);
    formData.append('fileableType', FileableType.property);

    try {
      const response = await fetch(`${api}/upload-file`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        dispatch(thunkGetProperty(propertyId!));
        message.success(`${file.name} uploaded successfully!`);
      } else {
        const error = await response.json();
        onError(error);
        message.error(`${file.name} upload failed!`);
      }
    } catch (error) {
      onError(error);
      message.error(`An error occurred while uploading ${file.name}`);
    }
  };

  // Optional: Validate files before upload
  const beforeUpload = (file: File) => {
    const isAllowed = allowedExtensions.includes(
      `.${file.name.split('.').pop()?.toLowerCase()}`
    );

    if (!isAllowed) {
      message.error(`${file.name} is not a valid file type!`);
    }

    return isAllowed || Upload.LIST_IGNORE; // Prevent upload if not allowed
  };

  const handleDelete = async (file: any) => {
    dispatch(thunkDeletePropertyFiles(file.uid))
      .unwrap()
      .then(() => {
        dispatch(thunkGetProperty(propertyId!));
        message.success(`${file.name} deleted successfully!`);
      })
      .catch((error: any) => {
        message.error(`Failed to delete ${file.name}: ${error.message}`);
      });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const formattedFileList = property
    ? property.files.map((file: propertyFile) => ({
        uid: String(file.id),
        name: file.name,
        status: 'done' as UploadFileStatus,
        url: file.url,
      }))
    : [];

  return (
    <div>
      {isLoading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      ) : (
        <div>
          {property && (
            <Dragger
              customRequest={handleCustomRequest}
              beforeUpload={beforeUpload}
              multiple={true}
              fileList={formattedFileList}
              onRemove={handleDelete}
              onPreview={handlePreview}
              maxCount={5}
              listType='picture-card'
            >
              <div
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <p className='ant-upload-text'>
                  Click or drag file to this area to upload
                </p>
                <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
              </div>
            </Dragger>
          )}

          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </div>
      )}
    </div>
  );
}
