import { useNavigate } from 'react-router-dom';
import { Property } from 'rentfree-api';
import { Card } from 'antd';

import './relatedProperties.css';

const { Meta } = Card;

interface Props {
  properties: Property[];
}

const RelatedProperties = (props: Props) => {
  const { properties } = props;
  const navigate = useNavigate();

  return (
    <div className='related-property-container'>
      <h3>Related Properties</h3>
      <div className='related-property-list'>
        {properties.map((property, index) => (
          <Card
            onClick={() => navigate(`/property/${property.id}`)}
            key={index}
            hoverable
            className='related-property-card'
            cover={
              <div className='property-image-container'>
                <img
                  src={
                    property.files[0]
                      ? property.files[0].url
                      : 'https://place-hold.it/500x500'
                  }
                  alt={property.title}
                />
              </div>
            }
          >
            <Meta title={property.title} description={property.address} />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RelatedProperties;
