import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';
import { Provider } from 'react-redux';
import { store } from './core/store/store';
// import AdminDashboardRoutes from './features/admin/AdminDashboardRoutes';

import appTheme from './theme';
import './App.css';
import Routes from './routes';
import LoadAppData from './core/components/LoadAppData';

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AntApp>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: appTheme.colorPrimary,
                  controlHeight: 42,
                },
              }}
            >
              <LoadAppData>
                <Routes />
              </LoadAppData>
            </ConfigProvider>
          </AntApp>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
