import {
  CloudOutlined,
  TeamOutlined,
  ShopOutlined,
  DashboardOutlined,
  VerifiedOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { AppLogo } from 'src/app/core/components';
import { useAppSelector } from 'src/app/core/store/hooks';
import { selectUser } from '../../auth/store/authSlice';

export default function AdminSiderMenu({ mode }: { mode: 'vertical' | 'inline' }) {
  const user = useAppSelector(selectUser);

  const items = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: <Link to={'/dashboard'}>Dashboard</Link>,
      isAllowed: true,
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: 'Users',
      isAllowed: ['admin', 'superadmin'].includes(user?.role!),
      children: [
        {
          key: '/users',
          label: <Link to={'/dashboard/users'}>View</Link>,
        },
        {
          key: '/users/create',
          label: <Link to={'/dashboard/users/create'}>Create</Link>,
        },
      ],
    },
    {
      key: 'properties',
      icon: <ShopOutlined />,
      label: 'Properties',
      isAllowed: ['admin', 'superadmin', 'landlord', 'agent'].includes(user?.role!),
      children: [
        {
          key: 'properties/index',
          label: <Link to={'/dashboard/properties'}>View</Link>,
        },
        {
          key: 'properties/create',
          label: (
            <Link to={'/dashboard/properties/create'}>
              <span className='label'>Create</span>
            </Link>
          ),
        },
      ],
    },
    {
      key: 'saved-properties',
      icon: <CloudOutlined />,
      isAllowed: true,
      label: <Link to={'/dashboard/saved-properties'}>My Saved Properties</Link>,
    },
    {
      key: 'verification',
      icon: <VerifiedOutlined />,
      isAllowed: ['admin', 'landlord', 'agent'].includes(user?.role!),
      label: <Link to={'/dashboard/verification'}>Verification</Link>,
    },
  ];

  const menuItems: MenuProps['items'] = items
    .filter((item) => item.isAllowed)
    .map(({ isAllowed, ...rest }) => rest);

  return (
    <>
      <AppLogo />
      <hr />
      <Menu
        theme='light'
        mode={mode}
        defaultSelectedKeys={['4']}
        items={menuItems}
        className='admin-sidebar-menu'
      />
    </>
  );
}
