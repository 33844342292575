import * as React from 'react';

export interface CountdownOptions {
  enabled?: boolean;
  onCompleted?: () => void;
}

const calcTimeRemaining = (milliSecondsToTimeout: number) => {
  const now = new Date().getTime();
  const distance = milliSecondsToTimeout - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  };
};

export default function useCountdownTimer(
  defaultMilliSecondsToTimeout: number,
  options: CountdownOptions = { enabled: false }
) {
  const { onCompleted } = options;
  const [time, setTime] = React.useState(() => calcTimeRemaining(0));
  const [isRunning, setIsRunning] = React.useState(false);
  const intervalID = React.useRef<NodeJS.Timeout | null>(null);

  const startTimer = (timeout: number) => {
    setTime(calcTimeRemaining(timeout));
    setIsRunning(true);

    intervalID.current = setInterval(() => {
      setTime(calcTimeRemaining(timeout));
    }, 1000);
  };

  const restart = React.useCallback(
    (milliSecondsToTimeout?: number) => {
      if (intervalID.current) {
        clearInterval(intervalID.current);
      }

      const timeout =
        new Date().getTime() + (milliSecondsToTimeout ?? defaultMilliSecondsToTimeout);
      startTimer(timeout);
    },
    [defaultMilliSecondsToTimeout]
  );

  React.useEffect(() => {
    const { days, hours, minutes, seconds } = time;

    if (isRunning && days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      clearInterval(intervalID.current!);
      setIsRunning(false);
      if (onCompleted) onCompleted();
    }
  }, [time, isRunning, onCompleted]);

  return {
    time,
    restart,
  };
}
