import { Menu, MenuProps } from 'antd';
import { NavLink } from 'react-router-dom';

export default function RightMenu(props: React.ComponentProps<typeof Menu>) {
  const items: MenuProps['items'] = [
    {
      key: 'about-us',
      label: <NavLink to='/about-us'>About us</NavLink>,
    },
    {
      key: 'register',
      label: <NavLink to='/register'>Register</NavLink>,
    },
    {
      key: 'login',
      label: (
        <NavLink data-testid='login-link' className='login-button' to='/login'>
          Login
        </NavLink>
      ),
    },
  ];
  return <Menu {...props} items={items} />;
}
