import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataConfig } from 'rentfree-api';
import { client } from 'src/app/services/client';

export const getConfigThunk = createAsyncThunk<DataConfig>(
  'config/getConfig',
  async () => {
    return client.service('data-config').find();
  }
);
