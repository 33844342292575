import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  GetProp,
  Input,
  InputNumber,
  message,
  Row,
  Upload,
  UploadProps,
} from 'antd';
import { useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export default function GuarantorForm() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Form layout='vertical'>
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
            <p>Upload NIMC Image</p>
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Row gutter={[4, 4]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='bvn'
                label='NIN/BVN'
                rules={[{ required: true, message: 'Please input your NIN/BVN' }]}
              >
                <Input type='text' placeholder='NIN/BVN' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='address'
                label='Address'
                rules={[{ required: true, message: 'Please input your address' }]}
              >
                <Input type='text' placeholder='Address' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='first-name'
                label='First Name'
                rules={[{ required: true, message: 'Please input your First Name' }]}
              >
                <Input type='text' placeholder='First Name' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='last-name'
                label='Last Name'
                rules={[{ required: true, message: 'Please input your Last Name' }]}
              >
                <Input type='text' placeholder='Last Name' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='dob'
                label='Date of birth'
                rules={[{ required: true, message: 'Please input your date of birth' }]}
              >
                <Input type='text' placeholder='Date of birth' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name='phone-number'
                label='Phone number'
                rules={[{ required: true, message: 'Please input your phone number' }]}
              >
                <InputNumber
                  addonBefore='+234'
                  style={{ width: '100%' }}
                  placeholder='phone number'
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
