import { Button } from 'antd';
import { Heart } from 'lucide-react';

import showMessage from '../utils/show-message';
import { thunkSaveProperty } from 'src/app/features/property/store/thunk';
import { useAppDispatch } from '../store/hooks';

export default function Like({ propertyId }: { propertyId: string }) {
  const dispatch = useAppDispatch();

  const handleSaveProperty = () => {
    dispatch(thunkSaveProperty({ propertyId: propertyId }))
      .unwrap()
      .then(() => {
        showMessage('success', 'Property saved successfully');
      })
      .catch(() => {
        showMessage('error', 'unable to save property');
      });
  };

  const handleLikeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleSaveProperty();
  };

  return (
    <Button
      icon={<Heart size={16} strokeWidth={1} />}
      type='text'
      className='sub-button'
      onClick={handleLikeClick}
    >
      Like
    </Button>
  );
}
