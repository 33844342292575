import * as React from 'react';

import {
  filterUndefinedValues,
  generatePriceOptions,
  capitalizeFirstLetter,
} from '../../utils/helpers';
import { Form, Select, Button, Col, AutoComplete, Row, Segmented } from 'antd';
import { ControlOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import useModalState from 'src/app/hooks/useModalState';
import AdvancedSearchDrawer from './components/AdvancedSearchDrawer';
import { GetPropertiesQuery } from '../../types/property';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { thunkLgaSearch } from 'src/app/features/property/store/thunk';
import { selectLgaSearchResults } from 'src/app/features/property/store/propertySlice';
import './SearchBox.css';
import storage from 'src/app/lib/storage';
import { CONFIG_KEY } from 'src/app/lib/variables';
import { DataConfig } from 'rentfree-api';
import { useState } from 'react';

interface Props {
  setQuery: (q: GetPropertiesQuery) => void;
  queryParams: GetPropertiesQuery;
}

export function SearchBox(props: Props) {
  const { isOpen, closeModal, openModal } = useModalState();
  const searchResults = useAppSelector(selectLgaSearchResults);
  const configData = React.useMemo(
    () => (storage.get(CONFIG_KEY) as DataConfig) ?? {},
    []
  );

  const { setQuery, queryParams } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isSearchPage = location.pathname === '/property';
  const dispatch = useAppDispatch();
  const [listingType, setListingType] = useState<{ listingType: string }>({
    listingType: 'rent',
  });

  const handleSearchQuery = (values: GetPropertiesQuery) => {
    const { propertyTypes, ...rest } = values;
    const propertyTypeQuery = propertyTypes
      ? propertyTypes.length === 0
        ? { propertyType: undefined }
        : { propertyType: propertyTypes.join('&') }
      : {};
    const queryObject = { ...rest, ...listingType, ...propertyTypeQuery };

    if (isSearchPage) {
      setQuery(queryObject);
      return;
    }

    const filteredQueryParams = filterUndefinedValues(queryObject);
    const hasQueryParams = Object.keys(filteredQueryParams).length > 0;

    if (hasQueryParams) {
      setQuery(filteredQueryParams);
      const queryStringParams = new URLSearchParams(
        filteredQueryParams as Record<string, string>
      ).toString();
      navigate(`/property?${queryStringParams}`, { state: filteredQueryParams });
    } else {
      navigate('/');
    }
  };

  const stateLgaSearch = (e: string) => {
    dispatch(thunkLgaSearch({ q: e }));
  };

  const priceOptions = generatePriceOptions(
    Number(configData.propertyPriceRange?.minPrice),
    Number(configData.propertyPriceRange?.maxPrice)
  );

  return (
    <>
      <Form layout='vertical' className='search-form' onFinish={handleSearchQuery}>
        {!isSearchPage && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Segmented
              options={['Rent', 'Sale']}
              value={capitalizeFirstLetter(listingType?.listingType)}
              onChange={(e) => setListingType({ listingType: e.toLowerCase() })}
            />
          </div>
        )}
        <Row gutter={[8, 0]} className='search-box' data-testid='search-box'>
          <Col xs={24} md={24} lg={4}>
            <Form.Item
              initialValue={queryParams.location}
              name={'location'}
              className='first-item'
            >
              <AutoComplete
                className='custom-placeholder'
                options={searchResults}
                placeholder='Location'
                onSearch={stateLgaSearch}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={4}>
            <Form.Item
              initialValue={queryParams.propertyType?.split('&')}
              name={'propertyTypes'}
            >
              <Select
                maxTagCount='responsive'
                className='custom-placeholder'
                mode='multiple'
                placeholder='Property Type'
                options={[
                  { label: 'House', value: 'house' },
                  { label: 'Apartment', value: 'apartment' },
                  { label: 'Guesthouse', value: 'guesthouse' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col flex='auto'>
            <div className='flex gap'>
              <Form.Item
                initialValue={queryParams.minPrice}
                name={'minPrice'}
                style={{ width: '100%' }}
              >
                <Select
                  placeholder='Min price'
                  className='custom-placeholder'
                  options={priceOptions}
                />
              </Form.Item>
              <Form.Item
                initialValue={queryParams.maxPrice}
                name={'maxPrice'}
                style={{ width: '100%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('minPrice') < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Max price must be greater than min price')
                      );
                    },
                  }),
                ]}
              >
                <Select
                  placeholder='Max price'
                  className='custom-placeholder'
                  options={priceOptions}
                />
              </Form.Item>
            </div>
          </Col>
          <div className='range-separator' />
          <Col flex='auto'>
            <div className='flex gap'>
              <Form.Item
                initialValue={queryParams.minBed}
                name={'minBed'}
                style={{ width: '100%' }}
              >
                <Select
                  placeholder='Min beds'
                  className='custom-placeholder'
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                  ]}
                />
              </Form.Item>
              <Form.Item
                initialValue={queryParams.maxBed}
                name={'maxBed'}
                style={{ width: '100%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('minBed') < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Max bed must be greater than min bed')
                      );
                    },
                  }),
                ]}
              >
                <Select
                  placeholder='Max beds'
                  className='custom-placeholder'
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                  ]}
                />
              </Form.Item>
            </div>
          </Col>

          <div className='range-separator hide' />

          {isSearchPage && (
            <Col flex='auto'>
              <div className='search-box-button'>
                <Button
                  style={{ width: '100%' }}
                  icon={<ControlOutlined />}
                  onClick={openModal}
                >
                  Filters
                </Button>

                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  htmlType='submit'
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {!isSearchPage && (
              <div className='search-home-btn'>
                <Button
                  style={{ width: '50%' }}
                  type='primary'
                  htmlType='submit'
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Form>

      <AdvancedSearchDrawer
        open={isOpen}
        onClose={closeModal}
        setQuery={setQuery}
        queryParams={queryParams}
      />
    </>
  );
}
