import * as React from 'react';
import { Card, Steps } from 'antd';
import KycForm from './components/KycForm';
import GuarantorForm from './components/GuarantorForm';
import DocumentForm from './components/DocumentForm';

export default function Verification() {
  const [current, setCurrent] = React.useState(0);

  const goToNextFlow = () => {
    setCurrent((prev) => prev + 1);
  };

  const steps = [
    {
      title: 'KYC',
      content: <KycForm goToNextFlow={goToNextFlow} />,
    },
    {
      title: 'Document',
      content: <DocumentForm goToNextFlow={goToNextFlow} />,
    },
    {
      title: 'Guarantor',
      content: <GuarantorForm />,
    },
  ];

  return (
    <div className='dashboard-page-wrapper'>
      <Steps
        current={current}
        items={steps}
        style={{ marginBottom: 40 }}
        progressDot
        responsive
      />
      <Card>
        <div>{steps[current].content}</div>
      </Card>
    </div>
  );
}
