import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from 'src/app/features/auth/store/authSlice';

interface AccessContol {
  permittedRoles: string[];
  condition?: () => boolean;
}

export default function AccessControl(props: React.PropsWithChildren<AccessContol>) {
  const { permittedRoles, condition, children } = props;
  const user = useAppSelector(selectUser);

  if (
    (permittedRoles && user && permittedRoles.includes(user.role)) ||
    (condition && condition())
  ) {
    return <div className='access-control'>{children}</div>;
  }

  return null;
}
