import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

interface Props {
  error?: boolean;
  errorMessage?: string;
  onRetry?: () => void;
  retryText?: string;
  loading?: boolean;
}

export default function LoadingScreen(props: React.PropsWithChildren<Props>) {
  const {
    children,
    error,
    errorMessage = 'Sorry, something went wrong',
    onRetry,
    retryText,
    loading,
  } = props;

  if (!loading && !error) return <>{children}</>;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      ) : (
        <div className='text-center'>
          <CloseOutlined style={{ color: 'red', fontSize: 100 }} />
          <p className='margin-bottom margin-top'>{errorMessage}</p>
          <Button onClick={onRetry}>{retryText ? retryText : 'Retry'}</Button>
        </div>
      )}
    </div>
  );
}
