/**
 * Decode string
 */

import dayjs from 'dayjs';

export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

// Function to filter out keys with undefined values
export const filterUndefinedValues = (obj: {}) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
};

export function replaceSpacesWithPlus(str: string) {
  return str.replace(/ /g, '+');
}

export function getDetailedRelativeTime(dateString: string): string {
  const now = dayjs();
  const date = dayjs(dateString);

  const days = now.diff(date, 'day');
  const hours = now.diff(date, 'hour') % 24;
  const minutes = now.diff(date, 'minute') % 60;

  let result = '';

  if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hours > 0) result += `${hours} hr${hours > 1 ? 's' : ''} `;
  if (minutes > 0) result += `${minutes} min${minutes > 1 ? 's' : ''} `;

  return result.trim() + ' ago';
}
export const truncateString = (text: string, length: number = 10) => {
  return `${text.substring(0, length)}...${text.substring(text.length - length + 3)}`;
};

// Money formatter

export function formatMoney(amount: string, locale = 'en-NG') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'NGN',
  }).format(+amount);
}

export const siteMap: Record<string, string> = {
  dashboard: '/dashboard',
  users: '/dashboard/users',
  properties: '/dashboard/properties',
};

export function truncateWords(text: string, wordLimit = 20) {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

interface PriceOption {
  label: string;
  value: number;
}

export const generatePriceOptions = (min: number, max: number): PriceOption[] => {
  if (!min || !max) return [];
  const options = [];
  const step = (max - min) / 19;

  const roundUp = (num: number) => {
    const factor = Math.pow(10, Math.ceil(Math.log10(num)) - 2);
    return Math.ceil(num / factor) * factor;
  };

  for (let i = 0; i < 20; i++) {
    const price = roundUp(min + step * i);
    options.push({
      label: `${price.toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
      value: price,
    });
  }

  return options;
};
