import { Outlet } from 'react-router-dom';
import LoadingScreen from 'src/app/core/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { thunkLogout } from 'src/app/features/auth/store/thunks';
import { socket } from 'src/app/services/client';
import {
  selectAuthLoading,
  selectIsAuthenticated,
  selectUser,
} from 'src/app/features/auth/store/authSlice';
import VerifyEmail from 'src/app/features/verify-email';

export default function ProtectedRoutesGuard() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const authLoading = useAppSelector(selectAuthLoading);
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  if (isAuthenticated === false && authLoading === false) {
    dispatch(thunkLogout());
  }

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
    dispatch(thunkLogout());
  });

  if (isAuthenticated && user?.emailVerifiedAt === null) return <VerifyEmail />;

  return (
    <>
      {authLoading && <LoadingScreen />}
      {isAuthenticated && !authLoading && (
        <div>
          <Outlet />
        </div>
      )}
    </>
  );
}
