import { Button, message } from 'antd';
import { Share } from 'lucide-react';
import { APP_URL } from 'src/app/lib/variables';

export default function LikeAndShare({ propertyId }: { propertyId: string }) {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'check out this property',
          text: 'I found something amazing!',
          url: `${APP_URL}/property/${propertyId}`,
        });
      } catch (error) {
        message.error('Failed to share');
      }
    }
  };

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShare();
  };

  return (
    <Button
      icon={<Share size={16} strokeWidth={1} />}
      type='text'
      className='sub-button'
      onClick={handleShareClick}
    >
      Share
    </Button>
  );
}
