import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/core/store/store';
import { thunkGetProperties, thunkGetProperty } from './thunk';
import { GetPropertiesQuery } from 'src/app/core/types/property';
import { thunkLgaSearch } from './thunk';
import { AutoCompleteProps } from 'antd';
import { Property } from 'rentfree-api';
import { Paginated } from '@feathersjs/feathers';

export interface PropertyState {
  filterValues: GetPropertiesQuery;
  loading: boolean;
  error: any;
  properties: Paginated<Property>;
  lgaSearchResults: AutoCompleteProps['options'];
  lgaSearchLoading?: boolean | null;
  property: Property | null;
}

const initialState: PropertyState = {
  properties: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
  loading: false,
  error: null,
  filterValues: {
    location: '',
    minBed: 0,
    maxBed: 0,
    minPrice: '',
    maxPrice: '',
    listingType: '',
    propertyType: '',
    amenities: [],
  },
  lgaSearchResults: [],
  lgaSearchLoading: null,
  property: null,
};

export const propertySlice = createSlice({
  name: 'propertySearch',
  initialState,
  reducers: {
    onPropertyFilters: (state, action: PayloadAction<GetPropertiesQuery>) => {
      state.filterValues = action.payload;
    },
    onClearFilters: (state) => {
      state.filterValues = {};
    },
    setProperty: (state, action: PayloadAction<Property>) => {
      state.property = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkLgaSearch.fulfilled, (state, action) => {
      state.lgaSearchResults = action.payload.data.map((lga) => {
        const formatted = `${lga.state} ${lga.lga ? `- ${lga.lga}` : ''}`;
        return {
          value: formatted,
          label: formatted,
        };
      });
    });
    builder.addCase(thunkLgaSearch.pending, (state) => {
      state.lgaSearchLoading = true;
    });
    builder.addCase(thunkLgaSearch.rejected, (state) => {
      state.lgaSearchLoading = false;
    });
    builder
      .addCase(thunkGetProperties.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunkGetProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = action.payload;
      })
      .addCase(thunkGetProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(thunkGetProperty.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunkGetProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.property = action.payload;
      })
      .addCase(thunkGetProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { onPropertyFilters, onClearFilters, setProperty } = propertySlice.actions;
export const selectGetProperties = (state: RootState) => state.property;
export const selectGetPropertiesData = (state: RootState) =>
  state.property.properties.data;
export const selectGetPropertiesError = (state: RootState) => state.property.error;
export const selectGetPropertiesLoading = (state: RootState) => state.property.loading;

export const selectPropertySearch = (state: RootState) => state.property.filterValues;
export const selectLgaSearchResults = (state: RootState) =>
  state.property.lgaSearchResults;
export const selectLgaSearchLoading = (state: RootState) =>
  state.property.lgaSearchLoading;

export default propertySlice.reducer;
