import rest from '@feathersjs/rest-client';
import authentication from '@feathersjs/authentication-client';
import { io } from 'socket.io-client';
import { feathers } from '@feathersjs/feathers';
import socketioClient from '@feathersjs/socketio-client';

// Create a socket.io connection with the server
const s = io(process.env.REACT_APP_API_BASE_URL!);
const socket = feathers();
socket.configure(socketioClient(s) as any);
socket.configure(
  authentication({
    storage: window.localStorage,
  })
);

// Create a REST connection
const client = feathers();

client.configure(
  rest(process.env.REACT_APP_API_BASE_URL).fetch(window.fetch.bind(window)) as any
);

client.configure(
  authentication({
    storage: window.localStorage,
  })
);

// Refresh the token when the user is still logged in
client.hooks({
  error: async (context) => {
    // We need to make sure this doesn't run on the login page
    if (
      context.error.name === 'NotAuthenticated' &&
      window.location.pathname !== '/login'
    ) {
      try {
        // await client.reAuthenticate(true);
        // If reauthentication is successful, retry the request
      } catch (error) {
        console.error('Error reauthenticating', error);
        // client.logout();
      }
    }
  },
});

export { client, socket };
