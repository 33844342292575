import * as React from 'react';
import EntryWrapper from 'src/app/core/components/entry-wrapper/EntryWrapper';
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import { Button, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { thunkResetPassword } from '../store/thunks';
import showMessage from 'src/app/core/utils/show-message';

import { CheckCircleOutlined } from '@ant-design/icons';
import '../login/components/LoginForm.css';
import '../login/Login.css';
import './restPassword.css';
import ResetPasswordForm from './components/ResetPasswordForm';
import { useSearchParams } from 'react-router-dom';
import { selectAuthLoading } from '../store/authSlice';

export default function ResetPassword() {
  const authLoading = useAppSelector(selectAuthLoading);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [successfulScreen, setSuccessfulScreen] = React.useState(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    dispatch(thunkResetPassword({ ...values, withToken: false }))
      .unwrap()
      .then((data) => {
        setSuccessfulScreen(true);
        showMessage('success', 'check your email');
      })
      .catch((error) => {
        showMessage('success', error.message ?? 'login failed');
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <EntryWrapper>
      <div className='login-container'>
        <a href='/'>
          <Logo />
        </a>

        {token ? (
          <ResetPasswordForm token={token} />
        ) : (
          <>
            {successfulScreen ? (
              <div className='login'>
                <p className='login-text'>Forget Password</p>

                <div className='successful-icon'>
                  <CheckCircleOutlined />
                </div>

                <p className='login__welcome-back'>
                  Please kindly check your email for the reset password link.
                </p>
                <div className='signup'>
                  Don’t have an account? <a href='/register'>Sign up</a>
                </div>
              </div>
            ) : (
              <div className='login'>
                <p className='login-text'>Forget Password</p>
                <p className='login__welcome-back'>
                  Please enter your email to reset your password.
                </p>
                <div className='login-wrapper'>
                  <Form
                    name='basic'
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                      label='Email'
                      name='email'
                      rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                      <Input placeholder='Enter your email' className='input' />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='entry-button'
                        loading={authLoading === true}
                      >
                        Reset password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>

                <div className='signup'>
                  Don’t have an account? <a href='/register'>Sign up</a>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </EntryWrapper>
  );
}
