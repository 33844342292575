import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import './verificationBanner.css';

export default function VerificationBanner() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleVerify = () => {
    navigate('/dashboard/verification');
  };

  if (location.pathname.includes('verification')) {
    return null;
  }

  return (
    <div className='verification-banner'>
      <div className='verification-banner__content'>
        <p className='verification-banner__title'>Verify your account</p>
        <p className='verification-banner__description'>
          Your properties will be restricted if you do not verify your account
        </p>
      </div>
      <div className='verification-banner__cta'>
        <Button type='primary' size='large' onClick={handleVerify}>
          Verify Now
        </Button>
      </div>
    </div>
  );
}
