import { Button } from 'antd';
import { Property } from 'rentfree-api';
import { ReactComponent as Logo } from 'src/app/asset/icons/bank.svg';
import './about-poster.css';

interface Props {
  property: Property;
}

export default function AboutPoster(props: Props) {
  const { property } = props;

  return (
    <div className='about-poster-wrapper'>
      <h3>About the {property.user.role}</h3>
      <div className='poster-title'>
        <div>
          <p className='name'>
            {property.user.firstName} {property.user.lastName}
          </p>
          <p className='address'>{property.user.address}</p>
        </div>
        <div>
          <Logo width={24} height={24} />
        </div>
      </div>

      <p className='description'>{property.user.about}</p>

      <Button>View agent profile</Button>
    </div>
  );
}
