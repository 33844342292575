import { createSlice } from '@reduxjs/toolkit';
import {
  thunkCreateProperty,
  thunkDeleteProperty,
  thunkListProperties,
  thunkPatchProperty,
  thunkGetProperty,
} from './thunks';
import { RootState } from 'src/app/core/store/store';
import { Property } from 'rentfree-api';
import { Paginated } from '@feathersjs/feathers';

interface AdminPropertyState {
  error: any;
  isLoading: boolean;
  isDeleting: boolean;
  properties: Paginated<Property>;
  property: Property | null;
}

const initialState: AdminPropertyState = {
  error: null,
  isLoading: false,
  isDeleting: false,
  property: null,
  properties: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
};

export const adminPropertySlice = createSlice({
  name: 'adminProperty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkCreateProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkCreateProperty.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunkCreateProperty.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });

    builder.addCase(thunkListProperties.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkListProperties.fulfilled, (state, action) => {
      state.isLoading = false;
      state.properties = action.payload;
    });
    builder.addCase(thunkListProperties.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(thunkPatchProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkPatchProperty.fulfilled, (state, action) => {
      state.isLoading = false;
      state.property = action.payload;
    });
    builder.addCase(thunkPatchProperty.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(thunkDeleteProperty.pending, (state) => {
      state.isDeleting = true;
    });
    builder.addCase(thunkDeleteProperty.fulfilled, (state) => {
      state.isDeleting = false;
    });
    builder.addCase(thunkDeleteProperty.rejected, (state) => {
      state.isDeleting = false;
    });

    builder.addCase(thunkGetProperty.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunkGetProperty.fulfilled, (state, action) => {
      state.isLoading = false;
      state.property = action.payload;
    });
    builder.addCase(thunkGetProperty.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const selectProperties = (state: RootState) => state.adminProperty;
export const selectGetPropertiesData = (state: RootState) =>
  state.adminProperty.properties;
export const selectPropertiesError = (state: RootState) => state.adminProperty.error;
export const selectIsLoadingProperties = (state: RootState) =>
  state.adminProperty.isLoading;
export const selectProperty = (state: RootState) => state.adminProperty.property;

export default adminPropertySlice.reducer;
